<script>

import typical from 'vue-typical'
import {Hooper, Slide} from "hooper";
import NavLanding from "../../components/structure/nav-landing";
import FooterLanding from "../../components/structure/footer-landing";
import 'hooper/dist/hooper.css';


/**
 * Crypto ICO-landing page
 */
export default {
  name:'starter',
  components: {FooterLanding, NavLanding, Hooper, Slide, typical},
  mounted() {
    window.scrollTo(0, 0)
  },
  data() {
    return {

      partners: ["1337", "emi", "emsi", "ensa_fes", "ensa_kenitra", "ensa_khouribga", "ensa_safi"],
      hooperSettings: {
        itemsToShow: 2,
        itemsToSlide: 2,
        //playSpeed: 3000,
        //transition:500,
        // hoverPause:true,
        breakpoints: {
          1000: {
            itemsToShow: 6,
            itemsToSlide: 6
          }
        }
      }

    };
  },

};
</script>

<template>
  <div>
    <nav-landing/>
    <div v-scroll-spy>
      <!-- hero section start -->
      <section class="section hero-section" id="home">
        <div class="container">
          <video autoplay muted loop id="myVideo">
            <source src="../../assets/helpers/crisisLOGOanimation.mp4" type="video/mp4">
          </video>
          <div class="row align-items-center content mx-0" >

            <div class="text-white-50">

              <typical
                  class="typicalWrapper text-white font-weight-semibold mb-4 hero-title"
                  :steps="[$t('landing.particular.section1.t'), 1000, $t('landing.particular.section1.title1'), 500, $t('landing.particular.section1.title2'), 1000]"
                  :loop="Infinity"
                  :wrapper="'h1'"
              ></typical>
              <p class="font-size-20 text-white">
                {{ $t('landing.particular.section1.legend') }}
              </p>

              <div class="button-items mt-4">
                <a @click="$router.push({'name':'Register'})" class="btn text-white" style="background-color: #02aae0">{{ $t('landing.nav.join') }}</a>
                <a :href="'mailto:' + $t('contact_email')" target="_blank"
                   class="btn btn-rounded">{{ $t('landing.nav.contact') }}</a>
              </div>
            </div>


          </div>
        </div>

      </section>
      <!-- hero section end -->
      <!-- currency price section start -->
      <section class="section bg-white p-0">
        <div class="container">
          <div class="currency-price">
            <div class="row d-flex justify-content-center">
              <div class="col-md-3">
                <div class="card card_top">
                  <div class="card-body text-center">
                    <h2 class="text-danger mb-4 mt-2">+ 50</h2>
                    <h4 >
                      {{ $t('landing.particular.section1.comp') }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card_top">
                  <div class="card-body text-center">
                    <h2 class="text-info mb-4 mt-2">+ 100</h2>
                    <h4>
                      {{ $t('landing.particular.section1.total') }}

                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card_top">
                  <div class="card-body text-center">
                    <h2 class="text-info mb-4 mt-2">+ 1 K</h2>
                    <h4>
                      {{ $t('landing.particular.section1.users') }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end container -->
      </section>
      <section class="section pt-0 bg-white" id="about">
        <div class="container">

          <div class="row">
            <div class="col-lg-12">
              <h1 class="text-center mb-5">{{ $t('landing.particular.section3') }}</h1>
            </div>
            <div class="col-lg-12">
              <hooper :autoPlay="true"  :settings="hooperSettings"    :infiniteScroll="true" :mouseDrag="false"
                      :touchDrag="false" :shortDrag="false" :keysControl="false" :wheelControl="false">

                <slide v-for="partener in partners" :key="partener">
                  <div class="item">
                    <div class="client-images">
                      <img :src="require(`@/assets/images/parteners/`+partener+`.png`)"
                           alt="client-img" class="mx-auto img-fluid d-block"/>
                    </div>
                  </div>
                </slide>


              </hooper>

            </div>
          </div>
          <!-- end row -->
        </div>
        <!-- end container -->
      </section>
      <section class="section py-4" id="why">
        <div class="container">
          <div class="row mx-0 mb-4">
            <div class="col-lg-12">
              <h2 class="text-center mb-4">{{ $t('landing.particular.section4.title') }}</h2>
              <h3 class="text-center mb-2">{{ $t('landing.particular.section4.subtitle') }}</h3>
              <h4 class="text-center text-muted mb-4">{{ $t('landing.particular.section4.desc') }}</h4>
            </div>

          </div>
          <div class="row">
            <div class="col-sm-3">
              <div class="card border">
                <div class="card-body">
                    <img width="70"
                         src="@/assets/images/icon/asser2x.png"
                         alt
                         class="img-fluid m-auto d-block"
                    />

                      <h4 class="text-center my-2">{{ $t('landing.particular.section5.div1.t') }}</h4>
                      <p class="text-muted">{{ $t('landing.particular.section5.div1.p') }}</p>

                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card border">
                <div class="card-body">
                      <img width="70"
                           src="@/assets/images/icon/cloud.png"
                           alt
                           class="img-fluid m-auto d-block"
                      />


                      <h4 class="text-center my-2">{{ $t('landing.particular.section5.div2.t') }}</h4>
                      <p class="text-muted">{{ $t('landing.particular.section5.div2.p') }}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card border">
                <div class="card-body">

                      <img width="70"
                           src="@/assets/images/icon/database.png"
                           alt
                           class="img-fluid m-auto d-block"
                      />

                      <h4 class="text-center my-2"> {{ $t('landing.particular.section5.div3.t') }}</h4>

                      <p class="text-muted">{{ $t('landing.particular.section5.div3.p') }}</p>


                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card border">
                <div class="card-body">

                      <img width="70"
                           src="@/assets/images/icon/locker.png"
                           alt
                           class="img-fluid m-auto d-block"
                      />

                      <h4 class="text-center my-2">{{ $t('landing.particular.section5.div4.t') }}</h4>
                      <p class="text-muted">{{ $t('landing.particular.section5.div4.p') }}</p>
                    </div>

              </div>
            </div>
          </div>
        </div>
        <!-- end container -->
      </section>
      <!-- Features start -->
      <section class="section bg-white" id="features">

        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <h3 class="text-center mb-4">{{ $t('landing.particular.section6.title') }}</h3>
              <h1 class="text-center text-primary mb-5">{{ $t('landing.particular.section6.link') }}</h1>

            </div>
          </div>

          <div class="row align-items-center pt-5">
            <div class="col-md-4 col-sm-8 mx-auto">
                <img
                    src="@/assets/images/crisis/home2-banner-main.png"
                    class="img-fluid mx-auto d-block"
                />
            </div>
            <div class="col-md-5  mx-auto">
              <h2 class="text-info mt-4">{{ $t('landing.particular.section7.t1') }}</h2>
              <p class="text-white my-4 font-size-16">{{ $t('landing.particular.section7.p1') }}</p>
            </div>
          </div>
          <!-- end row -->

          <div class="row align-items-center">
            <div class="col-md-5 mx-auto">
              <h2 class="text-info mt-2">{{ $t('landing.particular.section7.t2') }}</h2>

              <p class="my-4 font-size-16 text-white">{{ $t('landing.particular.section7.p2') }}</p>
            </div>
            <div class="col-md-4 col-sm-8 mx-auto">
              <div class="mt-3 ">
                <img src="@/assets/images/crisis/857.png" alt class="img-fluid mx-auto d-block"/>
              </div>
            </div>
          </div>


          <!-- end row -->
        </div>

        <!-- end container -->
      </section>
      <section class="my-5" id="galerie">
        <div class="row mx-0">
          <div class="col-lg-12">
            <h3 class="text-center  mb-4">{{ $t('landing.particular.section8.title') }}</h3>
          </div>
        </div>
        <div class="container">
          <div class="col-lg-12 ">
            <div class="row mx-0">
              <div class="col-xl-3">
                <div class="card border my-2">
                  <div class="card-body">
                    <h5 class="text-center">{{ $t('landing.particular.section8.part1.t') }}</h5>
                    <p class="text-muted mb-0">{{ $t('landing.particular.section8.part1.p') }}
                    </p>
                  </div>

                </div>
              </div>
              <div class="col-xl-3">
                <div class="card border my-2">
                  <div class="card-body ">
                    <h5 class="text-center">{{ $t('landing.particular.section8.part2.t') }}</h5>
                    <p class="text-muted mb-0">{{ $t('landing.particular.section8.part2.p1') }}</p>
                    <p class="text-muted mb-0">{{ $t('landing.particular.section8.part2.p2') }}</p>
                  </div>

                </div>
              </div>
              <div class="col-xl-6">
                <div class="card border my-2">
                  <div class="card-body">
                    <h5 class="text-center"><span
                        class="text-danger">{{ $t('landing.particular.section8.part3.attack') }}</span>
                      {{ $t('landing.particular.section8.part3.and') }} <span
                          class="text-success">{{ $t('landing.particular.section8.part3.defense') }}</span></h5>
                    <p class="text-muted mb-0">{{ $t('landing.particular.section8.part3.p1') }}</p>
                    <p class="text-muted mb-0">{{ $t('landing.particular.section8.part3.p2') }}</p>
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>
        <!-- end container -->
      </section>

      <section class="my-5" id="faqs">
        <div class="container">

          <!-- end row -->
          <div class="row mx-0">
            <div class="col-lg-12">
              <div class="vertical-nav">
                <b-tabs pills justified>
                  <b-tab active>
                    <template v-slot:title>
                      <i class="bx bx-help-circle nav-icon d-block mb-2"></i>
                      <p class="font-weight-bold mb-0">{{ $t('landing.particular.section9.ctf.title') }}</p>
                    </template>
                    <b-card-text>
                      <div class="card">
                        <div class="card-body">
                          <div class="row justify-content-center">
                            <div class="col-xl-4">
                              <img
                                  src="@/assets/images/crisis/CrisisCTF.png"
                                  alt
                                  class="img-fluid "
                              />
                            </div>
                            <div class="col-xl-5 my-auto">
                              <div class="font-size-16">
                                <p>{{ $t('landing.particular.section9.ctf.p1') }}</p>
                                <p>{{ $t('landing.particular.section9.ctf.p2') }}</p>
                                <p style="color: #6769e8">{{ $t('landing.particular.section9.ctf.p3') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <i class="bx bx-receipt nav-icon d-block mb-2"></i>
                      <p class="font-weight-bold mb-0">{{ $t('landing.particular.section9.training.title') }}</p>
                    </template>
                    <b-card-text>
                      <div class="card">
                        <div class="card-body">

                          <div class="row justify-content-center">
                            <div class="col-xl-4">
                              <img
                                  src="@/assets/images/crisis/CrisisTraining.png"
                                  alt
                                  class="img-fluid "
                              />
                            </div>
                            <div class="col-xl-5 my-auto">
                              <div class="font-size-16">
                                <p>{{ $t('landing.particular.section9.training.p1') }} </p>
                                <p>{{ $t('landing.particular.section9.training.p2') }} </p>
                                <p>{{ $t('landing.particular.section9.training.p3') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <i class="bx bx-timer d-block nav-icon mb-2"></i>
                      <p class="font-weight-bold mb-0">{{ $t('landing.particular.section9.careers.title') }}</p>
                    </template>
                    <b-card-text>
                      <div class="card">
                        <div class="card-body">


                          <div class="row justify-content-center">
                            <div class="col-xl-4">
                              <img src="@/assets/images/crisis/CrisisCareer.png"
                                   alt class="img-fluid "/>
                            </div>
                            <div class="col-xl-5 my-auto">
                              <div class="font-size-16">
                                <p>{{ $t('landing.particular.section9.careers.p1') }}</p>
                                <p>{{ $t('landing.particular.section9.careers.p2') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- end Faqs start -->

      <!-- Footer start -->
      <footer-landing/>
      <!-- Footer end -->
    </div>
  </div>
</template>
<style scoped src="@/assets/styles/custom/structure/landing.css"></style>
