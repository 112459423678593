<template>
    <b-modal id="modal-edit" centered :title="$t('edit')" ref="modal"  title-class="font-18" hide-footer
    @shown="create" @hide="hide">
                                    <b-card-text>
                                        <form id="form"  method="POST" @submit.prevent="handleSubmit">
                                            <div class="form-group  mb-0">
                                                <label>Content :</label>

                                                <b-form-textarea rows="4" v-model="c" class="form-control"
                                                                 :class="{ 'is-invalid': typesubmit && $v.c.$error }">

                                                </b-form-textarea>
                                                    <div v-if="typesubmit && $v.c.$error" class="invalid-feedback">
                                                        <span v-if="!$v.c.required"> {{$t('validation.required')}}</span>
                                                    </div>
                                                </div>

                                            <div class="form-group mt-3 mb-0">
                                                <input type="submit" class="btn float-right btn-primary"  :value="$t('post.save')"/>
                                            </div>
                                        </form>
                                    </b-card-text>
    </b-modal>
</template>

<script>
    import helper from "@/assets/helpers/helper";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "Edit",
        props:['content','post_id','type','company_id','comment_id'],
        data(){
            return {
                typesubmit: false,
                c:''
            }

        },
        validations: {
           c: {required},
        },
        methods: {
            create(){
                this.typesubmit=false
                this.c = this.content
            },
            handleSubmit() {
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                
                    return;

                }
                this.edit();

            },

            async edit() {
                let link = 'posts/' + this.post_id
                if (this.type == 'companies') link = 'companies/' + this.company_id + '/posts/' + this.post_id
                if (this.type == 'comments') link = 'posts/' + this.post_id + '/comments/' + this.comment_id
                if (this.type == 'cc') link = 'companies/' + this.company_id + '/posts/' + this.post_id + '/comments/' + this.comment_id
                let re = await helper.postData(link, {'content': this.c}, 's')
                if (re) {
                    this.typesubmit = false;
                    this.$emit('refresh', this.c)
                    this.$emit('close')
                    this.$refs['modal'].hide()
                }
            },
                hide(){
                    this.c = ''
                    // this.$emit('close')
                }


        }
    }
</script>

<style scoped>

</style>