<template>
    <div class="card">
        <div class="card-body" >
            <h4 class="card-title mb-3"> {{$t('dashboard.challenges.text')}}</h4>
            <div class="row" v-if="!load">
                <div class="col-xl-10 mx-auto">
                    <div class="card task-box card-body align-items-center text-center">
                        <b-skeleton width="90%" class="mb-2 "></b-skeleton>
                        <b-skeleton width="75%" class="mb-2 ml-2"></b-skeleton>
                        <b-skeleton width="50%" class=" ml-4" type="button"></b-skeleton>
                    </div>
                </div>
            </div>
          <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="3000"
              controls
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"  v-else>
            <b-carousel-slide  style="min-height: 150px"  class="card task-box"   v-for="chall of challenges" :key="chall.id">
              <template class="card-body text-center m-auto " >
                <h5 class="my-2"> {{chall.name}}</h5>
                <p class="text-muted">{{chall.score}} / {{chall.category.name}}</p>
                <div class="mt-3">
                  <router-link class="btn btn-primary btn-sm w-md" :to="{name:'Training', query: { challenge_id: chall.id,category_id:chall.category.id }}"> {{$t('dashboard.challenges.view')}}</router-link>
                </div>
              </template>
            </b-carousel-slide>

          </b-carousel>
        </div>
    </div>
</template>

<script>
    import helper from "@/assets/helpers/helper";

    export default {
        name: "newChallenges",
        data(){
            return{
                challenges: [],
                 load : false,
              slide: 0,
              sliding: null
            }
        },
        created(){
            this.loadNew()
        },
        methods:{
          onSlideStart() {
            this.sliding = true
          },
          onSlideEnd() {
            this.sliding = false
          },
            async loadNew() {
                this.challenges = await helper.getAll('challenges/new');
                this.load = true
            },
        }
    }
</script>

<style scoped>

    /deep/.carousel-item {
        cursor: pointer;
        transition: all .2s ease-in-out;
    }

    /deep/.carousel-item:hover {
        transform: scale(1.1);
        background-color: #4a5577;
    }
    /deep/.carousel-caption{
      bottom: 2px!important;
    }


</style>