<template>
    <div class="card" >
        <div class="card-body">
            <h4 class="card-title mb-3"> {{$t('dashboard.comp.upcoming')}}</h4>
            <p  v-if="upcoming_competitions.length == 0 && isLoadedComp" >
                {{$t('validation.msg')}} <br/>
                <span role="button" class="mt-2 contact font-weight-medium text-primary"  v-b-modal.modal-collaborate><i class="fa fa-arrow-right mr-2"></i> {{$t('validation.contact')}}</span>
            </p>
            <b-card-text >
                <simplebar style="max-height: 300px;" data-simplebar-auto-hide="false">
                        <div v-if="!isLoadedComp">
                            <b-skeleton-table :rows="2" :columns="3"></b-skeleton-table>
                        </div>
                    <table class="tab table table-centered table-nowrap" v-if="upcoming_competitions.length">
                        <thead>
                        <tr class="text-center">
                            <td> {{$t('dashboard.comp.name')}}</td>
                            <td> {{$t('dashboard.comp.teams')}}</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="data of upcoming_competitions" :key="data.id">
                            <td class="text-xl-center" :data-label="$t('dashboard.comp.name')">
                                <div>
                                    <router-link class="h5 mb-1 link" :to="{name:'ProfilCompetition',params:{id:data.id}}">{{data.name}}</router-link>
                                    <p class="text-muted mb-0" v-b-tooltip.hover :title="$t('dashboard.comp.begin_at')+' UTC | '+data.begin_at">
                                        {{data.begin_at}}</p>
                                </div>
                            </td>
                            <td class="text-xl-center" :data-label="$t('dashboard.comp.teams')">
                                <h5 class=" mb-0">{{data.teams_count}}</h5>
                            </td>

                            <td class="text-center">
                                <button type="button" class="btn btn-sm btn-primary" :title="$t('dashboard.comp.finished_at')+' UTC | '+data.finish_at" v-if="data.is_joined == 0"  @click="joinCompetition(data.id)" >{{$t('dashboard.comp.join')}}</button>
                                <button type="button" class="btn btn-sm btn-primary" :title="$t('dashboard.comp.finished_at')+' UTC | '+data.finish_at" v-if="data.is_joined == 1"  @click="unjoinCompetition(data.id)" >{{$t('dashboard.comp.unjoin')}}</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </simplebar>
            </b-card-text>
        </div>
        <unjoin-confirmation :id="selectedId" type="competitions" v-on:refresh="refreshUnjoin"/>
    </div>
</template>

<script>
    import simplebar from "simplebar-vue";
    import helper from "@/assets/helpers/helper";
    import UnjoinConfirmation from "@/components/popups/unjoinconfirmation";
    export default {
        name: "upcomingCompetitions",
        components:{UnjoinConfirmation, simplebar},
        data(){
            return{
                upcoming_competitions: [],
                isLoadedComp:false,
                selectedId:'',

            }
        },

        created(){

            this.loadCompetitions()
        },
        methods:{

            refreshUnjoin(){
                let index =  this.upcoming_competitions.findIndex(upcoming => upcoming.id === this.selectedId);
                this.upcoming_competitions[index].teams_count = this.upcoming_competitions[index].teams_count - 1
                this.upcoming_competitions[index].is_joined = 0
            },
            unjoinCompetition(id){
                this.selectedId = id
                this.$root.$emit('bv::show::modal', 'unjoin')
            },
            async joinCompetition(id){
                let r =  await helper.postReturnData('competitions/'+id+'/join');
                if(r)  this.$router.push({name:'ProfilCompetition',params:{'id':id}})
            },
            async loadCompetitions() {
                let upcoming = await helper.loadData('competitions/upcoming', 1);
                this.upcoming_competitions = upcoming
                this.isLoadedComp = true
            },
        }
    }
</script>

<style scoped>
p{
  line-height: 1.5rem;
}
.contact:hover{
  text-decoration: underline;
}
</style>