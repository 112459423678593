<template>
    <footer class="py-4" style="background-color: #2a3042">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="mb-4 text-center">
                        <img src="@/assets/images/logo-light.png" alt width="200"/>
                    </div>
                    <div class="row text-center justify-content-center align-items-center">
                          <span class=" copyright mx-3"> © {{ new Date().getFullYear() }} {{$t('landing.footer')}}</span>
                          <router-link class="mx-3" to="/tos">{{$t('tos.title')}}</router-link>
                          <router-link class="mx-3 " to="/privacypolicy">{{$t('privacy.title')}}</router-link>


                    </div>

                </div>
            </div>
        </div>
        <!-- end container -->
    </footer>
</template>

<script>
    export default {
        name: "footer-landing"
    }
</script>

<style scoped>

</style>