<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-3"> {{ $t('dashboard.streaming') }}</h4>
      <div class="row" v-if="!load">
        <div class="col-xl-6">
          <b-skeleton width="90%" class="mb-2 mt-1"></b-skeleton>
        </div>
        <div class="col-xl-6">
          <b-skeleton width="50%" class=" ml-auto" type="button"></b-skeleton>
        </div>
        <b-col cols="12" class="mt-3">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
      </div>

      <b-carousel
          id="carousel-2"
          v-model="slide2"
          :interval="3000"
          controls
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd" v-else>
        <b-carousel-slide style="min-height: 250px" class="card task-box  m-auto" v-for="str of streamings"
                          :key="str.id">
          <template class="card-body p-0">
            <div class="card-body p-0">

                  <div class="row mx-0">
                    <router-link class="col-xl-9 h5 text-truncate font-size-15 link"
                                 :to="{name:'ProfilUser',params:{id:str.creator.user_id}}">
                      {{ str.creator.username }}
                    </router-link>
                    <span class="col-xl-2  btn-sm py-1 text-center text-white mb-1" style="border: 1px solid #f02f2f;border-radius: 4px;background-color: #f02f2f">{{$t('profil_user.settings.live') }}</span>
                  </div>

              <div class="col-xl-12 m-auto" style="cursor: pointer"
                   @click="$router.push({name:'watchStream',params:{id:str.id}})">

                <div class="user-images position-relative overflow-hidden">
                  <img :src="'https://static-cdn.jtvnw.net/previews-ttv/live_user_'+str.username_twitch+'-800x800.jpg'"
                       height="180"  width="100%" />
                  <div class="image-hover-data">
                    <div class="product-elements-icon">
                      <ul class="row align-items-center m-0 p-0 list-inline">
                        <li><span class="pr-3 text-white">{{ str.title }}</span></li>
                        <li class="ml-auto"><span class="pr-3 text-white text-right">{{str.viewer_count }} viewer</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <p v-if="streamings.length == 0 && load" class="text-center">
        {{ $t('validation.no_stream') }}
      </p>
    </div>
  </div>
</template>

<script>
import helper from "@/assets/helpers/helper";

export default {
  name: "newStreamings",
  data() {
    return {
      streamings: [],
      load: false,
      slide2: 0,
      sliding: null
    }
  },
  created() {
    this.loadNew()
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    async loadNew() {
      this.streamings = await helper.getAll('streamings/new');
      this.load = true
    },
  }
}
</script>

<style scoped>
/deep/.carousel-caption{
  top: 0px!important;
  padding-bottom: 2px!important;
  width: 85%;
  text-align: left!important;
  left: 8% !important;
  right: 8% !important;

}

/deep/.carousel-control-prev,/deep/ .carousel-control-next{
  width: 8% !important;
}

.image-hover-data {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  bottom: -60px;
  padding: 5px;
  border-radius: 0 0 5px 5px;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.45s ease 0s;
}

.user-images:hover .image-hover-data {
  bottom: 0;
  transition: all 0.45s ease 0s;
}



</style>