<template>
    <nav class="navbar navbar-expand-xl navigation fixed-top sticky" id="navbar">
        <div class="container">
            <a class="navbar-logo my-2 link" @click="$router.push({'name':'Crisi5'})">

                <img src="@/assets/images/logo-light.png" alt height="60" class="logo"/>
            </a>

            <button
                    type="button"
                    class="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                    data-toggle="collapse"
                    data-target="#topnav-menu-content"
                    @click="toggleMenu()"
            >
                <i class="fa fa-fw fa-bars"></i>
            </button>

            <div class="collapse navbar-collapse text-right" id="topnav-menu-content">
                <ul
                        class="navbar-nav ml-auto"
                        id="topnav-menu"
                        v-scroll-spy-active="{ selector: 'a.nav-link' }"
                >
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{name:'landingUniversity'}">{{$t('landing.nav.university')}}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{name:'landingBusiness'}">{{$t('landing.nav.business')}}</router-link>
                    </li>
                </ul>


                <div>
                    <b-dropdown variant="white" right toggle-class="header-item">
                        <template v-slot:button-content>
                            <img class :src="flag" alt="Header Language" height="16" />

                        </template>
                        <b-dropdown-item
                                class="notify-item "
                                v-for="(entry, i) in languages"
                                :key="`Lang${i}`"
                                :value="entry"
                                @click="setLanguage(entry.language, entry.title, entry.flag)"
                                :class=" {'active' : lan === entry.language}"
                        >
                            <img :src="`${entry.flag}`" alt="user-image" class="mr-1" height="12" />

                        </b-dropdown-item>
                    </b-dropdown>
                    <router-link class="btn sign-btn w-xs mx-3" :to="{ name: 'Register' }">{{$t('landing.nav.join')}}</router-link>
                    <router-link class="btn login-btn w-xs " :to="{ name: 'Login' }">{{$t('landing.nav.login')}} </router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import i18n from "../../i18n";

    export default {
        name: "nav-landing",
        data(){
            return{
                languages: [
                    {
                        flag: require("@/assets/images/flags/us.jpg"),
                        language: "en",
                        title: "English",
                    },
                    {
                        flag: require("@/assets/images/flags/french.jpg"),
                        language: "fr",
                        title: "French",
                    },

                ],
                lan: i18n.locale,
                text: null,
                flag: null,
                value: null,
            }
        },
        created() {
            window.addEventListener("scroll", this.windowScroll);
        },
        destroyed() {
            window.removeEventListener("scroll", this.windowScroll);
        },
        mounted() {
            this.value = this.languages.find((x) => x.language === i18n.locale);
            this.text = this.value.title;
            this.flag = this.value.flag;

        },
        methods:{
            /**
             * Window scroll method
             */
            windowScroll() {
                const navbar = document.getElementById("navbar");
                if (
                    document.body.scrollTop >= 50 ||
                    document.documentElement.scrollTop >= 50
                ) {
                    navbar.classList.add("nav-sticky");
                } else {
                    navbar.classList.remove("nav-sticky");
                }
            },
            setLanguage(locale, country, flag) {
                this.lan = locale;
                this.text = country;
                this.flag = flag;
                i18n.locale = locale;

            },
            /**
             * Toggle menu
             */
            toggleMenu() {
                document.getElementById("topnav-menu-content").classList.toggle("show");
            },
        }
    }
</script>

<style scoped>
    .navigation {
        padding-bottom: 12px;
        border-bottom: none !important;
    }

    .login-btn {
        position: relative;
        z-index: 1;
        color: #b6daf9;
        font-size: 12px !important;
        text-transform: uppercase;
        line-height: 33px;
        padding: 0 20px;
        min-width: 100px;
        height: 35px;
        border-radius: 50px;
        border: 2px solid;
        letter-spacing: 1px;
        border-color: #02aae0;
    }
    .sign-btn {
      position: relative;
      z-index: 1;
      font-size: 12px !important;
      text-transform: uppercase;
      line-height: 33px;
      padding: 0 20px;
      min-width: 100px;
      height: 35px;
      border-radius: 50px;
      border: 2px solid;
      letter-spacing: 1px;
      background-color: #7450fe;
      color: white;
      border-color: #7450fe;

    }
    .container {
        max-width: 1200px !important;
    }

    /deep/ .dropdown-menu{
        min-width: 12px!important;
    }


    .login-btn:hover {
        background-color: #02aae0;
        color: white;
    }
    .sign-btn:hover {
      background-color: inherit;
      color: #b6daf9;
    }
    .nav-link {
        line-height: 60px!important;
        font-size: 20px!important;
    }
    @media screen and (max-width: 900px) {
        .logo{
            height: 40px!important;
        }
        .nav-link{
            text-align: center;
        }
    }

</style>