<script>
import simplebar from "simplebar-vue";
import helper from "@/assets/helpers/helper";

export default {
  name: "post",
  components: { simplebar },
  props: ["post", "type", "companie"],
  data() {
    return {
      submitted: false,
      title: "Copy",
      content: "",
      company_id: "",
      comment_id: "",
      comments: [],
      interactions: [
        {
          id: 1,
          title: "like",
          icon: "bx bxs-like text-primary",
        },
        {
          id: 2,
          title: "happy",
          icon: "bx bxs-happy-alt text-warning",
        },
        {
          id: 3,
          title: "love",
          icon: "bx bxs-heart text-danger",
        },
        {
          id: 4,
          title: "shocked",
          icon: "bx bxs-shocked text-warning",
        },
        {
          id: 5,
          title: "sad",
          icon: "bx bxs-sad text-warning",
        },
      ],
      selectedType: "",
      sharing: {
        url:this.$parent_stream+"/posts/" + this.post.id,
        title: 'Check out this post on CRISIS \n'+this.$parent_stream+"/posts/" + this.post.id,
        description: 'Check out this post on CRISIS \n'+this.$parent_stream+"/posts/" + this.post.id,
        quote: 'Check out this post on CRISIS \n'+this.$parent_stream+"/posts/" + this.post.id,
        hashtags: "CRISI5",
        twitterUser: "CRISI5",
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fah fa-lg fa-linkedin",
          color: "#007bb5",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fah fa-lg fa-twitter",
          color: "#1da1f2",
        },
      ],
    };
  },
  created() {
    if (this.post.new) {
      this.post.comm_page = 1;
      this.post.interactions_count = 0;
      this.post.comments_count = 0;
      this.post.comments = [];
      this.post.my_interaction = [];
      this.post.user = this.$store.state.user;
      this.post.new = false;
    }
    if(this.companie) this.post.is_my_post =  this.companie.is_manager
    this.comments = this.post.comments.reverse();
  },

  methods: {
    async myComment() {
      this.content = document.getElementById("my_cmt" + this.post.id).innerText;
      if (this.content) {
        if (this.type == "c") {
          let r = await helper.postReturnData(
            "companies/" +
              this.companie.id +
              "/posts/" +
              this.post.id +
              "/comments",
            { content: this.content }
          );
          if (r) {
            r.user = this.$store.state.user;
            this.comments_count = this.post.comments_count + 1;
            this.comments.unshift(r);
            document.getElementById("my_cmt" + this.post.id).innerText = "";
            this.content = "";
          }
        } else {
          let r = await helper.postReturnData(
            "posts/" + this.post.id + "/comments",
            { content: this.content }
          );
          if (r) {
            r.user = this.$store.state.user;
            this.comments.unshift(r);
            this.post.comments_count = this.post.comments_count + 1;
            document.getElementById("my_cmt" + this.post.id).innerText = "";
            this.content = "";
          }
        }
      }
    },
    showEdit(post, type, comment_id) {
      if (this.type == "c") this.company_id = this.companie.id;
      this.comment_id = comment_id;
      this.selectedType = type;
      if (this.type == "c" && type != "comments")
        this.selectedType = "companies";
      if (this.type == "c" && type == "comments") this.selectedType = "cc";
      this.$emit("show");
      this.$emit(
        "show-edit",
        post,
        this.post.id,
        this.company_id,
        comment_id,
        this.selectedType
      );
    },
    onCopy: function() {
      setTimeout(() => {
        this.title = "Copied";
      }, 200);
      this.title = "Copy";
    },
    async loadComments() {
      let comm = await helper.loadData(
        "posts/" + this.post.id + "/comments",
        this.post.comm_page + 1
      );
      let comments = comm.data;
      if (comments.length) {
        this.post.comm_page = this.post.comm_page + 1;
        comments.forEach((element) => {
          comments.push(element);
        });
      }
    },
    async savePost() {
      if (this.type == "c")
        await helper.postReturnData(
          "companies/posts/" + this.post.id + "/save"
        );
      else await helper.postReturnData("posts/" + this.post.id + "/save");
    },
    async deletePost() {
      if (this.type == "c")
        await helper.deleteItem(
          "companies/" + this.companie.id + "/posts/" + this.post.id
        );
      else await helper.deleteItem("posts/" + this.post.id);
      console.log("Event Emitted");
      this.$emit("delete-post");
    },
    async intract(i) {
      let a = {};
      if (this.type == "c")
        a = await helper.postData(
          "companies/" +
            this.companie.id +
            "/posts/" +
            this.post.id +
            "/interactions",
          { relation: i }
        );
      else
        a = await helper.postData("posts/" + this.post.id + "/interactions", {
          relation: i,
        });
      if (!a) {
        this.post.interactions_count = this.post.interactions_count - 1;
        this.post.my_interaction = [];
      } else {
        if (!this.post.my_interaction[0]) this.post.interactions_count = this.post.interactions_count + 1;
        this.$set(this.post.my_interaction, 0, a);
      }
    },
  },
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <div class="media">
        <b-avatar
          :src="post.user.avatar"
          v-if="type != 'c' && post.user_id != $store.state.user.user_id"
          class="mr-4 "
        ></b-avatar>
        <b-avatar
          :src="$store.state.user.avatar"
          v-if="type != 'c' && post.user_id == $store.state.user.user_id"
          class="mr-4 "
        ></b-avatar>
        <b-avatar
          :src="companie.logo"
          v-if="type == 'c' && companie.id != $store.state.company.id"
          class="mr-4 "
        ></b-avatar>
        <b-avatar
          :src="$store.state.company.logo"
          v-if="type == 'c' && companie.id == $store.state.company.id"
          class="mr-4 "
        ></b-avatar>

        <div class="media-body overflow-hidden">
          <h5>
            <router-link
              class="h5 text-truncate font-size-15 link"
              v-if="type != 'c'"
              :to="{ name: 'ProfilUser', params: { id: post.user_id } }"
              >{{ post.user.username }}</router-link
            >
          </h5>
          <h5>
            <router-link
              class="h5 text-truncate font-size-15 link"
              v-if="type == 'c'"
              :to="{ name: 'ProfilCompany', params: { id: companie.id } }"
              >{{ companie.name }}</router-link
            >
          </h5>
          <router-link
            class="text-muted link"
            v-if="type != 'c'"
            :to="{ name: 'postPage', params: { id: post.id } }"
            >{{ post.time_diff }}.</router-link
          >
          <router-link
            class="text-muted link"
            v-else
            :to="{
              name: 'postCompany',
              params: { company_id: companie.id, id: post.id },
            }"
            >{{ post.time_diff }}.</router-link
          >
        </div>
        <b-dropdown
          class="float-right ml-2"
          variant="white"
          right
          toggle-class="text-muted"
        >
          <template v-slot:button-content>
            <i class="mdi mdi-dots-horizontal font-size-18"></i>
          </template>
          <b-dropdown-item @click="savePost">{{
            $t("post.save")
          }}</b-dropdown-item>
          <b-dropdown-item
            @click="showEdit(post.content, 'posts')"
            v-if="post.is_my_post"
            >{{ $t("post.edit") }}</b-dropdown-item
          >
          <b-dropdown-item @click="deletePost" v-if="post.is_my_post">{{
            $t("post.delete")
          }}</b-dropdown-item>
        </b-dropdown>
      </div>

      <p class="text-muted">{{ post.content }}</p>
      <hr />
      <div class="d-flex justify-content-between align-items-center">
        <div class="like-block position-relative d-flex align-items-center">
          <div class="d-flex align-items-center mr-2">
            <div class="like-data">
              <span
                class="dropdown-toggle"
                :id="'tooltip' + post.id"
                aria-haspopup="true"
                aria-expanded="true"
                role="button"
              >
                <i
                  class="interactions"
                  :class="
                        post.my_interaction.length != 0
                      ? `${interactions[post.my_interaction[0].relation - 1].icon}`
                      : 'bx bxs-like'
                  "
                ></i>
              </span>
              <b-tooltip
                :target="'tooltip' + post.id"
                placement="bottom"
                triggers="hover"
              >
                <i
                  class="interactions"
                  v-for="inter of interactions"
                  :key="inter.id"
                  :class="inter.icon"
                  :title="inter.title"
                  @click="intract(inter.id)"
                ></i>
              </b-tooltip>
            </div>
            <div
              class="total-like-block ml-2 mr-3"
              v-if="post.interactions_count"
            >
              {{ post.interactions_count }}
            </div>
          </div>
          <div class="total-comment-block" v-if="post.comments_count">
            {{ post.comments_count }} {{ $t("post.comments") }}
          </div>
        </div>
        <div class="share-block d-flex align-items-center feather-icon mr-3">
          <b-dropdown
            class="float-right ml-2"
            variant="white"
            right
            toggle-class="text-muted"
          >
            <template v-slot:button-content>
              <i class="fas fa-share mr-2"></i>
              <span class="link">{{ $t("post.share") }}</span>
            </template>

            <ShareNetwork
              class="dropdown-item"
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :style="{ color: network.color }"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
              :hashtags="sharing.hashtags"
              :twitterUser="sharing.twitterUser"
            >
              <i :class="network.icon"></i>
              <span class="ml-3">{{ network.name }}</span>
            </ShareNetwork>
            <b-dropdown-item
              v-b-tooltip.hover.top
              :title="title"
              v-clipboard:copy="sharing.url"
              v-clipboard:success="onCopy"
              alt="Copy to clipboard"
            >
              <i class="bx bx-paste font-size-16 align-middle mr-1"></i>
              {{ $t("post.copy") }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <hr />
      <b-card-text class="d-none d-md-block">
        <simplebar
          style="max-height: 120px;padding-right: 12px"
          data-simplebar-auto-hide="false"
        >
          <div class="row my-1 " v-if="post.comments.length >= 2">
            <div class="col-12">
              <div class="text-right">
                <span class="text-success link" @click="loadComments"
                  >{{ $t("post.loadmore") }}
                </span>
              </div>
            </div>
          </div>
          <ul class="list-unstyled chat-list">
            <li class v-for="(data, index) of comments" :key="data.id">
              <div class="media">
                <b-avatar
                  :src="data.user.avatar"
                  v-if="data.user.avatar"
                  class="mr-4 "
                ></b-avatar>

                <b-avatar
                  v-else
                  variant="primary"
                  :text="data.user.username.substr(0, 1)"
                  class="align-baseline mr-4"
                ></b-avatar>

                <div class="media-body overflow-hidden">
                  <router-link
                    class="h5 p-0 text-truncate border-0 link"
                    :to="{ name: 'ProfilUser', params: { id: data.user_id } }"
                    >{{ data.user.username }}</router-link
                  >
                  <p class="text-muted">{{ data.time_diff }}</p>
                  <p class="mb-1">{{ data.content }}</p>
                </div>

                <i
                  class="float-right mt-1 fa fa-edit fa-1x link"
                  v-if="data.is_my_comment"
                  @click="showEdit(data.content, 'comments', data.id)"
                ></i>
              </div>

              <hr v-if="index != post.comments.length - 1" />
            </li>
          </ul>
        </simplebar>
      </b-card-text>
      <div class="p-3 chat-input-section d-none d-md-block">
        <div class="col-xl-12">
          <span
            :id="'my_cmt' + this.post.id"
            class="t form-control pr-5"
            role="textbox"
            style="border-radius: 30px"
            contenteditable
          ></span>
          <div class="chat-input-links">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <a
                  v-b-tooltip.hover
                  placement="top"
                  title="Send"
                  @click="myComment"
                >
                  <i class="mdi mdi-send"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
i.interactions {
  font-size: 24px !important;
  margin-right: 6px !important;
  cursor: pointer !important;
}
.t {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: none;
  min-height: 40px;
  height: auto;
  line-height: 20px;
}

.t[contenteditable]:empty::before {
  content: "Write a comment...";
  color: gray;
}
</style>
