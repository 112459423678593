import Vue from 'vue'
import Swal from "sweetalert2";
import axios from 'axios'
import i18n from "@/i18n";

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: false,
    animation: false,
    timer: 2000,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
var msg_success = i18n.tc('validation.msg_success')
var msg_error = i18n.tc('validation.msg_error')
var  helper = {

    getAll(table){
        return  Vue.prototype.$http
            .get(table)
            .then(response => {
                return  response.data;
            })
            .catch(error => {
                Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
                return false
            })
    },
    loadData(table,page){
        return  Vue.prototype.$http
            .get(table+'?page='+page)
            .then(response => {
                return  response.data;
            })
            .catch(() => {
                return false
            })
    },


    getItem(table,id){
        return Vue.prototype.$http
            .get(table+'/'+id)
            .then(response => {
                return response.data
            })
            .catch(error => {
                Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
                return false
            })
    },
    addItem(table,item) {
        return Vue.prototype.$http
            .post(table, item)
            .then(response => {
                Toast.fire({icon: 'success', title: msg_success})
                return response.data
            })
            .catch(error => {
                Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
                return false
            })
    },
    postData(lien,item,type) {
        return Vue.prototype.$http
            .post(lien, item)
            .then(response => {

                if(type === 's') {
                    return true
                }
                else {
                      return response.data
                }
            })
            .catch(error => {
                console.log(error.response.data.error)
               if(type == 's')   Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
               else  Toast.fire({icon: 'error', title: 'Oops...', text: msg_error })
                return error
            })
    },
    postReturnData(lien,item,api_ref="platform") {
        console.log(api_ref)
        let http;
        if(api_ref=='platform') http = Vue.prototype.$http;
        else {
            http  = Vue.prototype.$koth;

            }
        return http
            .post(lien, item)
            .then(response => {
                    Toast.fire({icon: 'success', title: msg_success})
                    return response.data
            })
            .catch(error => {
                Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
                console.log(error.response.data.error)
                return false
            })
    },
    getReturnData(lien) {
        return Vue.prototype.$http
            .get(lien)
            .then(response => {
                Toast.fire({icon: 'success', title: response.data.msg})
                return response.data
            })
            .catch(error => {
                Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
                console.log(error.response.data.error)
                return false
            })
    },
    deleteItem(link){
        return Vue.prototype.$http
            .delete(link)
            .then(response => {
                Toast.fire({icon: 'success', title: msg_success})
                return response.data
            })
            .catch(error => {
                Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})

            })

    },
    forceFileDownload(response) {
        let filename = response.headers['content-disposition'].split(';')[1].split('=')[1]
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      },
      downloadWithAxios(api,data,method='post') {
        let url = Vue.prototype.$url_api+api
        axios({

          method: method,
          headers:{
            'Authorization': 'Bearer ' +localStorage.getItem('token') ,
                },
          url,
          responseType: 'arraybuffer',
          data:data,

        })
          .then((response) => {
            this.forceFileDownload(response)
            console.log(response.data)
            Toast.fire({icon: 'success', title: msg_success})
          })
          .catch(() => console.log('error occured'))
      },
    }


export default helper
