import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import store from '../store'

const routes = [

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/logins/login')
  },

  {
    path: '/questions',
    name: 'FirstInfos',
    component: () => import(/* webpackChunkName: "FirstInfos" */ '@/views/logins/FirstInfos')
  },
  {
    path: '/recover_password',
    name: 'RecoverPassword',
    component: () => import(/* webpackChunkName: "RecoverPassword" */ '@/views/logins/recoverpwd')
  },
  {
    path: '/email_confirmation',
    name: 'confirmationEmail',
    component: () => import(/* webpackChunkName: "RecoverPassword" */ '@/views/logins/confirmationEmail')
  },


  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "Register" */ '@/views/logins/register')
  },
  {
    path: '/users/:user_id/badges/:badge_id',
    name: 'ProfilBadge',
    component: () => import(/* webpackChunkName: "ProfilBadge" */'@/views/profiles/profileBadge')
  },
  {
    path: '/university',
    name: 'landingUniversity',
    component: () => import(/* webpackChunkName: "landingUniversity" */ '@/views/landingPage/landingUniversity')
  },
  {
    path: '/business',
    name: 'landingBusiness',
    component: () => import(/* webpackChunkName: "landingBusiness" */ '@/views/landingPage/landingBusiness')
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "notFound" */ '@/views/errors/404')
  },
  {
    path: '/tos',
    name: 'TOS',
    component: () => import(/* webpackChunkName: "TOS" */ '@/views/landingPage/tos')
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/views/landingPage/privacyPolicy')
  },
  
  {
    path: '/home',
    name: 'starter',
    component: () => import(/* webpackChunkName: "starter" */ '@/views/landingPage/starter')
  },
  {
    path: '/',
    redirect:localStorage.getItem('token')? require('@/views/pages/dashboard').default : require( '@/views/landingPage/starter').default
  },

  {
    path: '',
    name: 'Vertical',
    component: () => import(/* webpackChunkName: "Vertical" */ '@/components/structure/vertical'),
    meta: {
      requiresAuth: true
    },

    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/pages/dashboard'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/bounty',
        name: 'Bounty',
        component: () => import(/* webpackChunkName: "Bounty" */ '@/views/pages/Bounty'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/training',
        name: 'Training',
        component: () => import(/* webpackChunkName: "Training" */'@/views/pages/training'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/competitions',
        name: 'Competitions',
        component: () => import(/* webpackChunkName: "Competitions" */'@/views/pages/competitions'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/teams',
        name: 'Teams',
        component: () => import(/* webpackChunkName: "Teams" */'@/views/pages/teams'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "Users" */'@/views/pages/users'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/profile',
        name: 'MyProfil',
        component: () => import(/* webpackChunkName: "MyProfil" */'@/views/profiles/profile'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/users/:id',
        name: 'ProfilUser',
        component: () => import(/* webpackChunkName: "ProfilUser" */'@/views/profiles/profileUser'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/teams/:id',
        name: 'ProfilTeam',
        component: () => import(/* webpackChunkName: "ProfilTeam" */'@/views/profiles/profileTeam'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/competitions/:id/challenges',
        name: 'Competition',
        component: () => import(/* webpackChunkName: "Competition" */'@/views/pages/competition'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/competitions/:id/profile',
        name: 'ProfilCompetition',
        component: () => import(/* webpackChunkName: "ProfilCompetition" */'@/views/profiles/profileCompetition'),
        meta: {
          title:'',
          requiresAuth: true
        },
      },
      {
        path: '/my-competitions',
        name: 'MyCompetitions',
        component: () => import(/* webpackChunkName: "MyCompetitions" */'@/views/pages/myCompetitions'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/payment',
        name: 'Payment',
        component: () => import(/* webpackChunkName: "Payment" */'@/views/pages/Payment'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/competitions/:id/scoreboard',
        name: 'ScoreBoardCompetition',
        component: () => import(/* webpackChunkName: "ScoreBoardCompetition" */'@/views/pages/scoreboard'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/koth/rooms/:id',
        name: 'KothRoom',
        component: () => import(/* webpackChunkName: "KothRoom" */ '../views/profiles/KothRoom'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/competitions/:id/statistics',
        name: 'statisticsCompetition',
        component: () => import(/* webpackChunkName: "statisticsCompetition" */'@/views/pages/statistics'),
        meta: {
          requiresAuth: true
        },
      },
      // {
      //   path: '/calendar',
      //   name: 'Calendar',
      //   component: () => import(/* webpackChunkName: "Calendar" */'@/views/pages/calendar'),
      //   meta: {
      //     requiresAuth: true
      //   },
      // },
      {
        path: '/badges',
        name: 'Badges',
        component: () => import(/* webpackChunkName: "Badges" */'@/views/pages/badges'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/streaming',
        name: 'Streaming',
        component: () => import(/* webpackChunkName: "Streaming" */'@/views/pages/streamers'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/streaming/:id',
        name: 'watchStream',
        component: () => import(/* webpackChunkName: "watchStream" */'@/views/profiles/watchStream'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/posts/:id',
        name: 'postPage',
        component: () => import(/* webpackChunkName: "postPage" */'@/views/profiles/postPage'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/companies/:company_id/posts/:id',
        name: 'postCompany',
        component: () => import(/* webpackChunkName: "postPage" */'@/views/profiles/postCompany'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/hall_of_fame',
        name: 'hallOfFame',
        component: () => import(/* webpackChunkName: "hallOfFame" */'@/views/pages/hallOfFame'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/saved_posts',
        name: 'SavedPosts',
        component: () => import(/* webpackChunkName: "SavedPosts" */'@/views/pages/savedPosts'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/crisis_academy',
        name: 'CrisisAcademy',
        component: () => import(/* webpackChunkName: "AdaptiveLearning" */'@/views/errors/maintenance'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/games',
        name: 'Games',
        component: () => import(/* webpackChunkName: "Games" */'@/views/errors/maintenance'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/careers',
        name: 'Careers',
        component: () => import(/* webpackChunkName: "Careers" */'@/views/pages/careers'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/machines',
        name: 'Machines',
        component: () => import(/* webpackChunkName: "Machines" */'@/views/errors/maintenance'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/koth',
        name: 'Koth',
        component: () => import(/* webpackChunkName: "Koth" */'@/views/pages/Koth'),
        meta: {
          requiresAuth: true
        },
      },

      {
        path: '/companies',
        name: 'Companies',
        component: () => import(/* webpackChunkName: "Companies" */'@/views/pages/companies'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/universities',
        name: 'Universities',
        component: () => import(/* webpackChunkName: "Universities" */'@/views/pages/universities'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/companies/:id',
        name: 'ProfilCompany',
        component: () => import(/* webpackChunkName: "ProfilCompany" */'@/views/profiles/profileCompany'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/universities/:id',
        name: 'ProfilUniversity',
        component: () => import(/* webpackChunkName: "ProfilUniversity" */'@/views/profiles/profileUniversity'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/access',
        name: 'Access',
        component: () => import(/* webpackChunkName: "Access" */'@/views/pages/Access'),
        meta: {
          requiresAuth: true
        },
      }
    ]
  }

]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
  mode:'history'
})

const DEFAULT_TITLE = 'CRISIS';



router.afterEach((to,from) => {
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE +' - '+ to.name;
  });
  if(from.name == 'Competition' || from.name == 'ScoreBoardCompetition') {
    store.state.pusher_crisis.unsubscribe('private-competitions_' + from.params.id)
    store.state.pusher_crisis.unsubscribe('private-competitions_' + from.params.id+'_teams_'+store.state.team.id)
  }
  if(from.name == 'Koth') {
    store.state.pusher_koth.unsubscribe('private-koth')
  }
});



export default router
