<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title mb-3"> {{$t('dashboard.comp.live')}}</h4>
            <p  v-if="live_competitions.length == 0 && isLoadedComp" >
                {{$t('validation.msg_live')}}
            </p>
            <b-card-text >
                <simplebar style="max-height: 160px;" data-simplebar-auto-hide="false">

                        <div v-if="!isLoadedComp">
                            <b-skeleton-table :rows="2" :columns="3"></b-skeleton-table>
                        </div>
                    <table class="tab table table-centered table-nowrap" v-if="live_competitions.length">
                        <thead>
                        <tr class="text-center">
                            <td></td>
                            <td> {{$t('dashboard.comp.name')}}</td>
                            <td> {{$t('dashboard.comp.teams')}}</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="data of live_competitions" :key="data.id">
                            <td class="p-0"><router-link class="bx bx-right-arrow-circle px-2 link text-success font-size-20" v-if="data.is_joined == 1" title="challenges" :to="{name:'Competition',params:{id:data.id}}"></router-link></td>
                            <td :data-label="$t('dashboard.comp.name')">
                                <div class="text-xl-center">
                                    <router-link class="h5 mb-1 link" :to="{name:'ProfilCompetition',params:{id:data.id}}">{{data.name}}</router-link>
                                    <p class="text-muted mb-0" v-b-tooltip.hover :title="$t('dashboard.comp.begin_at') + ' UTC | '+data.begin_at">{{data.begin_at}}</p>
                                </div>
                            </td>
                            <td class="text-xl-center" :data-label="$t('dashboard.comp.teams')">
                                <h5 class=" mb-0">{{data.teams_count}}</h5>
                            </td>
                            <td class="text-center">
                                <button type="button" class="btn btn-sm btn-primary"  :title="data.time_diff"  v-if="data.is_joined == 0" @click="joinCompetition(data.id)" >{{$t('dashboard.comp.join')}}</button>
                                <button type="button" class="btn btn-sm btn-primary"  :title="data.time_diff" v-if="data.is_joined == 1" @click="unjoinCompetition(data.id)" >{{$t('dashboard.comp.unjoin')}}</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </simplebar>
            </b-card-text>
        </div>
        <unjoin-confirmation :id="selectedId" type="competitions" v-on:refresh="refreshUnjoin" />
    </div>
</template>
<script>
    import simplebar from "simplebar-vue";
    import helper from "@/assets/helpers/helper";
    import UnjoinConfirmation from "@/components/popups/unjoinconfirmation";
    export default {
        name: "liveCompetitions",
        components:{ UnjoinConfirmation, simplebar},
        data(){
            return{
                live_competitions: [],
                isLoadedComp:false,
                selectedId:'',

            }
        },

        created(){

            this.loadCompetitions()
        },
        methods:{

            async joinCompetition(id){
                let r =  await helper.postReturnData('competitions/'+id+'/join');
                if(r) this.$router.push('competitions/'+id+'/challenges')

            },
             refreshUnjoin(){
               let index =  this.live_competitions.findIndex(live => live.id === this.selectedId);
               this.live_competitions[index].teams_count = this.live_competitions[index].teams_count - 1
               this.live_competitions[index].is_joined = 0
             },
             unjoinCompetition(id){
                this.selectedId = id
                 this.$root.$emit('bv::show::modal', 'unjoin')
             },
             async loadCompetitions() {
                let live = await helper.loadData('competitions/live', 1);
                this.live_competitions = live
                this.isLoadedComp = true
            },
        }
    }
</script>
<style scoped>
p{
  line-height: 1.5rem;
}
</style>

