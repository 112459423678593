import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import vco from "v-click-outside"
import Scrollspy from 'vue2-scrollspy';
import VueSlickCarousel from 'vue-slick-carousel'
import i18n from './i18n'
import VueSweetalert2 from 'vue-sweetalert2';
import VueClipboard from 'vue-clipboard2';
import "@/assets/styles/index.scss";
import vueCountryRegionSelect from 'vue-country-region-select'
import axios from 'axios'
import VueSocialSharing from 'vue-social-sharing'
import VueAlertify from 'vue-alertify'
import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
import InfiniteLoading from 'vue-infinite-loading';
import VueTree from '@ssthouse/vue-tree-chart'

Vue.config.productionTip = false


Vue.prototype.$url_front = process.env.VUE_APP_URL_FRONT
Vue.prototype.$url_api = process.env.VUE_APP_URL_API
// Vue.prototype.$api_koth = 'http://127.0.0.1:8000/api/'
Vue.prototype.$api_koth = process.env.VUE_APP_API_KOTH
Vue.prototype.$parent_stream = process.env.VUE_APP_PARENT_STREAM
const token = localStorage.getItem('token')

Vue.prototype.$http = axios.create({
    baseURL: Vue.prototype.$url_api,
    headers: {"Content-Type": "application/json"}
});
Vue.prototype.$koth = axios.create({
    baseURL: Vue.prototype.$api_koth,
    headers: {"Content-Type": "application/json"}
});

if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
    Vue.prototype.$koth.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

Vue.use(VueAlertify)
Vue.use(VueSlickCarousel)
Vue.use(VueSweetalert2)
Vue.use(VueSocialSharing);
Vue.use(vco)
Vue.use(vueCountryRegionSelect)
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(InfiniteLoading, {
    props: {
        spinner: 'spiral',
        /* other props need to configure */
    }
});
Vue.component('apexchart', VueApexCharts)
Vue.component('vue-tree', VueTree)

Sentry.init({
    Vue,
    dsn: "https://5c1dc6e7dd654b0aaafd88a179111654@o1128267.ingest.sentry.io/6170814",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "ctf.crisi5.com", /^\//],
        }),
    ],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')


