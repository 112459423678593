<template>
    <div class="card">
        <div class="card-body ">
            <form id="form"  method="POST" @submit.prevent="handleSubmit">
            <div  class="input-group mb-2 mr-sm-3 form-control" style="height: 120px">
                <b-avatar :src="logo" ></b-avatar>
             <b-form-textarea rows="4" :placeholder="companie_id?$t('post.text_company'):$t('post.text_post')+$store.state.user.username+'?'"  v-model="content"
                              class="border-0" style="resize: none!important;" :class="{ 'is-invalid': typesubmit && $v.content.$error }">

             </b-form-textarea>
                <div v-if="typesubmit && $v.content.$error" class="invalid-feedback">
                    <span v-if="!$v.content.required"> {{$t('validation.required')}}</span>
                </div>
            </div>
            <div class="col-auto">
                <input type="submit" :disabled="isload" class="btn btn-primary btn-rounded float-right" :value="isload ?$t('post.btn')+'ing...':$t('post.btn')" />
            </div>
            </form>
        </div>
    </div>
</template>

<script>

    import helper from "@/assets/helpers/helper";
    import {required} from "vuelidate/lib/validators";
    export default {
        name: "postInput",
        props:['logo','companie_id'],
        data(){
            return{
                typesubmit: false,
              isload: false,

                content:'',

            }
        },
        validations: {
            content: {required},
        },
        methods: {
            handleSubmit() {
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.createPost();

            },
           async createPost(){
               let link = ''
             this.isload =true
              if(this.companie_id)  link = 'companies/'+this.companie_id+'/'
              let re =  await helper.postReturnData(link+'posts',{'content':this.content})
             this.isload = false
               if(re) {
                   this.content=""
                   this.typesubmit = false;
                   this.$emit('refresh',re)
               }
            },
        }


    }
</script>

<style scoped>

</style>