<script>
  import appConfig from "@/app.config";
  import Swal from "sweetalert2";
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-right',
    showConfirmButton: false,
    animation: false,
    timer: 2000,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  export default {
    name: "app",
    page: {
      // All subcomponent titles will be injected into this template.
      titleTemplate(title) {
        title = typeof title === "function" ? title(this.$store) : title;
        return title ? `${title} | ${appConfig.title}` : appConfig.title;
      }
    },

    watch: {
      /**
       * Clear the alert message on route change
       */
      // eslint-disable-next-line no-unused-vars
      $route(to, from) {
        // clear alert on location change
        //this.clearNotification();
      }
    },
           created: function () {
            console.log('%cHello CRISIS!', "background-color: #222; font-family: monospace; font-weight: bold; font-size: 36px; color: #FFF; text-shadow: 3px 3px 0 #2948df, 5px 5px 0 #34c38f");

            this.$http.interceptors.response.use(response => response,error => {
            return new Promise(() => {
              console.log(this.$route.path)
              if (error.response.status === 401 && !this.$store.state.error) {
                  if(error.response.data.code_error == 1) {
                    this.$router.push({'name':'Login', query: { redirect: this.$route.path }});
                    Toast.fire({icon: 'error', title: 'Oops...', text: 'Un-Authorized !'})
                    localStorage.removeItem('token');
                    delete this.$http.defaults.headers.common['Authorization'];

                  }
                  if(error.response.data.code_error == 2){
                    Toast.fire({icon: 'error', title: 'Oops...', text: 'You Should Verify Your Email'})
                    this.$router.push({'name':'confirmationEmail'});
                  }
                  if(error.response.data.code_error == 3){
                    Toast.fire({icon: 'error', title: 'Oops...', text: 'This is Your First Time ! Complete the Questions'})
                    this.$router.push({'name':'FirstInfos'});
                  }
                  if(error.response.data.code_error == 4){
                    Toast.fire({icon: 'error', title: 'Oops...', text: 'You are Banned !'})
                    this.$router.push({'name':'Login', query: { redirect: this.$route.path }});
                  }
                  this.$store.commit('interceptError',true)


              }
              if (error.response.status === 404) {
                this.$router.push("/not-found");
              }
              throw error;
            });
        });
            this.$koth.interceptors.response.use(response => response,error => {
               return new Promise(() => {
                 console.log(this.$route.path)
                 if (error.response.status === 401 && !this.$store.state.error) {

                     this.$router.push({'name':'Login', query: { redirect: this.$route.path }});
                     Toast.fire({icon: 'error', title: 'Oops...', text: 'Un-Authorized !'})
                     localStorage.removeItem('token');
                     delete this.$http.defaults.headers.common['Authorization'];

                   this.$store.commit('interceptError',true)


                 }
                 if (error.response.status === 404) {
                   this.$router.push("/not-found");
                 }
                 throw error;
               });
             });
        },
    methods: {

      // clearNotification: notificationMethods.clear
    }
  };
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
<style>
  html {
    zoom: 0.9; /* Old IE only */
    --scrollbarBG: #CFD8DC;
    --thumbBG: #90A4AE;
  }
  body::-webkit-scrollbar {
    width: 8px;
  }
  body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  body::-webkit-scrollbar-track {
    background: none;
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
  }
  .modal-backdrop{
    width: 100% !important;
    height: 100% !important;
  }
</style>
