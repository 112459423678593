<script>
    import 'hooper/dist/hooper.css';
    import Post from "@/components/post";
    import PostInput from "@/components/postInput";
    import appConfig from "@/app.config.json";
    import NewChallenges from "@/components/newChallenges";
    import UpcomingCompetitions from "@/components/upcomingCompetitions";
    import NewStreamings from "@/components/newStreamings";
    import LiveCompetitions from "@/components/liveCompetitions";
    import Edit from "../../components/popups/edit";


    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Dashboard",
            meta: [{ name: "description", content: appConfig.description }]
        },
        name: 'Dashboard',
        components: {
            Edit,
            LiveCompetitions,
            NewStreamings,
            UpcomingCompetitions,
            NewChallenges,
            PostInput,
            Post
        },
        data() {
            return {
                page_posts:1,
                title: "Dashboard",
                posts: [],
                new_post:false,
                avatar:'',
                content:'',
                post_id:'',
                s_index:'',
                company_id:'',
                comment_id:'',
                type:'',

            };
        },
        methods: {
          scrollToTop() {
            window.scrollTo(0,0);
            this.new_post = false
          },
            editPost(post,post_id,company_id,comment_id,type){
                console.log(type)
                this.content = post
                this.post_id = post_id
                this.company_id = company_id
                this.comment_id = comment_id
                this.type = type
                this.$root.$emit('bv::show::modal', 'modal-edit')
            },
            refreshPost(content){
                if(this.type == 'posts' || this.type == 'companies') this.posts[this.s_index].content = content
                if(this.type == 'comments' || this.type == 'cc') {
                    this.posts[this.s_index].comments.forEach(element => {
                        if(element.id == this.comment_id) element.content = content
                    });
                }
            },
          NewPost(post){
            post.new = true
            this.posts.unshift(post)
          },

            deleteThisPost(index){
                this.posts.splice(index,1);
            },
            infiniteHandler($state) {
                this.$http.get('posts?page=' +this.page_posts).then(({ data }) => {
                    if (data.length) {
                        this.page_posts += 1;
                        this.posts.push(...data);
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            },
        }


    };
</script>

<template>



        <div class="row">
            <div class="col-xl-4 col-lg-12 col-sm-12">
                <div class="hidden_icons justify-content-center mb-4">
                        <a title="Challenges" @click="$router.push({name:'Training'})" class="link mr-5 "><i class="bx bx-extension bx-md"></i></a>
                        <a title="Streaming" @click="$router.push({name:'Streaming'})" class="link mr-5 "><i class="bx bxs-video-recording bx-md"></i></a>
                        <a title="Competitions" @click="$router.push({name:'Competitions'})" class="link "><i class="bx bx-flag bx-md"></i></a>

                </div>

                <div class="div1 d-none d-xl-block">
                    <new-challenges/>
                  <upcoming-competitions/>

                </div>
            </div>
            <div class="col-xl-4 col-lg-12 col-sm-12 ">
              <div class="text-center mb-2 position-fixed" style="z-index: 1;width: 30%;" v-if="new_post">
                <button class="btn btn-primary btn-rounded " @click="scrollToTop()">
                  <i class="fa fa-arrow-up text-white mr-1"></i>
                  New Posts
                </button>
              </div>
                    <post-input  :logo="$store.state.user.avatar" v-on:refresh="NewPost"></post-input>

                    <!-- Posts  -->
                    <div v-for="(post,index) in posts" :key="post.id">
                        <post  :post="post" v-on:delete-post="deleteThisPost(index)" v-on:show-edit="editPost" v-on:show="s_index = index"></post>
                    </div>
                    <edit :content="content" :post_id="post_id" :type="type" :company_id="company_id" :comment_id="comment_id"  v-on:refresh="refreshPost" />
                    <infinite-loading @infinite="infiniteHandler">
                        <div slot="no-more">{{$t('validation.no_more_posts')}}</div>
                        <div slot="no-results">{{$t('validation.no_posts')}}</div>
                    </infinite-loading>


            </div>
            <div class="col-xl-4 d-none d-md-block">
                <div class="div2  d-none d-md-block">
                  <new-streamings/>
                   <live-competitions/>
                </div>
            </div>
        </div>
        <!-- end row -->

</template>
<style scoped>

    @media screen and (min-width: 900px) {
        .div2 {
            position: fixed !important;
            width: 27% !important;
            right: 8px;
        }
        .div1 {
            position: fixed !important;
            width: 27% !important;

        }

        .hidden_icons {
            display: none;
        }


    }

    @media screen and (max-width: 1000px) {

        .hidden_icons {
            display: flex;
        }

    }

</style>
